.elegant-table {
  width: 100%;
  border-collapse: collapse;
}

.elegant-table thead {
  background-color: #28a745; /* Green background */
  color: white; /* White text color */
}

.elegant-table thead th {
  font-size: 1.2rem; /* Make the header text larger */
  font-weight: bold; /* Make the header text bold */
  padding: 10px; /* Add padding for better spacing */
}

.elegant-table tbody td {
  padding: 10px; /* Add padding for better spacing */
}
