/* RentalConstitution.css */

:root {
  --primary-color: #333;
  --secondary-color: #666;
  --text-color: #000;
  --background-color: #fff;
}

.constitution-container {
  max-width: 800px;
  margin: auto;
  padding: 2rem;
  font-family: Arial, sans-serif;
  color: var(--text-color);
  line-height: 1.6;
}

.heading {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.section {
  margin-bottom: 2rem;
}

.section-heading {
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.signatures {
  text-align: center;
  margin-top: 2rem;
}

.signatures p {
  margin: 0;
}

.signatures br {
  margin: 1rem 0;
}
